import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import { withFirebase } from './firebase.context'
// import components
import MSBookingTable from '../elements/Table.MSBooking';
// import default data
import { defaultMSBookingTableData } from '../constants/msBookingTableData';

class MSBookingSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingData: { ...defaultMSBookingTableData },
      showCompleted: false
    }
  }

  componentDidMount() {
    this.fetchBookings();
  }

  fetchBookings = () => {
    let msBookingData = {};
    let dataArrayForTableRows = [];

    this.props.firebase.msrequestdata()
      .orderBy('created')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // deep clone booking data
          let obj = {};
          obj = JSON.parse(JSON.stringify(doc.data()));
          let isComplete = obj.data[2].value === "complete";
          // add individual record to super array
          if (!(isComplete && !this.state.showCompleted)) {
            dataArrayForTableRows.push(obj);
          }
        });

        // generate table component header
        msBookingData.head = defaultMSBookingTableData.head;
        // generate table component body
        msBookingData.body = dataArrayForTableRows;

        // order from most recent
        msBookingData.body.reverse();
        this.setState({ bookingData: msBookingData })
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  handleCheckShowCompleted = (event) => {
    const target = event.target;

    this.setState({
      showCompleted: target.checked
    }, this.fetchBookings);
  }

  render() {
    // console.log(this.state);
    return (
      <div css={css`
        width: 100%;
        padding: 20px 0;
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 20px 0;
        }
      `}>
        <h2>MS Booking Schedule</h2>
        <label>
          <input
            name="showCompleted"
            type="checkbox"
            checked={this.state.showCompleted}
            onChange={this.handleCheckShowCompleted} />
          Show Completed Bookings
        </label>
        <MSBookingTable bookingData={this.state.bookingData} />
      </div>
    )
  }
}

export default withFirebase(MSBookingSchedule);