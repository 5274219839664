import React from 'react';

/**
 * 
 * @param {object} props: data for table head and body 
 * @param {array: string} props.head: table head
 * @param {array: object} props.body: table body
 */

const LabMeetingScheduleTable = (props) => {

  return (
    <table className="table is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          {
            props.scheduleData.head.map((item, index)=>{
              return (
                <th key={index}>{item}</th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          props.scheduleData.body.map((item, index)=>{
            return (
              <tr key={index}>
                <td key={`date-${index}`}>{item.date}</td>
                <td key={`project-${index}`}>{item.project}</td>
                <td key={`update-${index}`}>{item.update}</td>
                <td key={`room-${index}`}>{item.room}</td>
                <td key={`time-${index}`}>{item.time}</td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default LabMeetingScheduleTable