import React from "react"
import { Link } from "gatsby"
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { IconContext } from "react-icons";
import { MdBook } from 'react-icons/md';
// import components
import Layout from "../components/layout"
import SEO from "../components/seo";
import Menu from "../components/menu.main";
import LabMeetingTable from "../components/table.labmeeting";
import MSBookingTable from "../components/table.msbooking";
import MSStatusTable from "../components/table.mstatus";
import AccountSettings from "../components/account";
import Content from "../elements/MDDisplay";

// *** link activation values
// *** the values are used to determine which component to render
// lab-meeting-schedule
// ms-status
// ms-booking
// account-settings

class IndexPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeMenu: 'lab-meeting-schedule',
      fileNameList: [],
      fileContent: ""
    }
  }

  getFileNameList = (list) => {
    // console.log(list);
    this.setState({fileNameList: list});
  }

  getFileContent = (content) => {
    // console.log(content);
    this.setState({fileContent: content});
  }

  activateMenuLink = (value) => {
    this.setState({activeMenu: value});
  }

  render() {
    // console.log(this.props);
    // console.log(this.state);
    return (
    <Layout>
      <SEO title="Home" />

      <div css={css`
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
      `}>
        <div css={css`
          width: 18%;
        `}>
          <Menu 
            activateMenuLink = {this.activateMenuLink} 
            reportFileNameList = {this.getFileNameList}
            reportFileContent = {this.getFileContent}
          />
        </div>

        <div css={css`
          width: 80%;
        `}>
        {
          this.state.activeMenu === "lab-meeting-schedule"
          &&
          <LabMeetingTable />
        }
        {
          this.state.activeMenu === "ms-booking"
          &&
          (<div>
            <div css={css`
              width: 100%;
              font-size: 1.5rem;
              font-weight: bold;
              text-align: center;
              cursor: pointer;
              div a {
                color: #3273dc;
              }
              &:hover {
                text-shadow: 2px 1px #ddd;
              }
            `}>
              <div>
                <IconContext.Provider value={{color:'#3273dc', size: 28}}>
                  <span css={css`vertical-align: sub;`}>
                    <MdBook />
                  </span>
                </IconContext.Provider>
                <Link to="/msrequest/">Book your MS equipment</Link>
              </div>
            </div>

            <MSBookingTable />
          </div>)
        }
        {
          this.state.activeMenu === "ms-status"
          &&
          <MSStatusTable />
        }
        {
          this.state.activeMenu === "account-settings"
          &&
          <AccountSettings />
        }
        {
          this.state.fileNameList !== undefined && this.state.fileNameList.indexOf(this.state.activeMenu) > -1
          &&
          <div>
            <Content 
              mdString = {this.state.fileContent}
            />
          </div>
        }
        </div>
      </div>
    </Layout>
    )
  }
}

export default IndexPage
