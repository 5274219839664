import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.index';
// import data
import {mainMenuData} from '../constants/menuData';

class MainMenu extends React.Component {
  constructor(props){
    super(props);
    this.state={
      mainMenu: [],
      fileList: [], //simple array, containing all file names, not full path
      activeList: mainMenuData[0].subList[0].value,
    }

    this.storeRef = props.firebase.storage.ref();
    this.menu = JSON.parse(JSON.stringify(mainMenuData)); //clone menu
  }

  async componentDidMount(){
    let tagList = await getFileTagList(this.props.firebase);
    // console.log(tagList);
    let catList = await getFileCategoryList(this.props.firebase);
    // console.log(catList);

    // will be used to render file content in `index.js`
    let fileNameList = [];

    // ***
    // generate the JSON object for LAB FILES menu section
    let fileMenuSubList = [];

    for(let cat of catList){

      let catObj = {};
      catObj.label = cat;
      catObj.value = cat.split(' ').join('-');
      catObj.subList = [];

      let fileRef = this.storeRef.child(cat);
      let fileList = await getFileList(fileRef);
      // console.log(fileList);

      fileList.forEach(file=>{
        let obj = {};
        obj.label = file.name.split('-').join(' ');
        obj.value = file.name;
        obj.link = file.ref; //not used to render UI, but for fetching in file content later
        obj.subList = null;
        catObj.subList.push(obj);
        // save a record of file name list
        fileNameList.push(file.name);
      })
      
      fileMenuSubList.push(catObj);
    }
    // console.log(fileMenuSubList);

    // ***
    // generate the main menu
    let _menu = this.menu.map(list=>{
      if(list.label === 'Lab Files'){
        list.subList = JSON.parse(JSON.stringify(fileMenuSubList));
      }
      return list; 
    })
    this.setState({
      mainMenu: _menu,
      fileList: fileNameList
    });

    this.props.reportFileNameList(fileNameList);
  }

  async componentDidUpdate(){
    // let tagList = await getFileTagList(this.props.firebase);
    // console.log(tagList);
    // let catList = await getFileCategoryList(this.props.firebase);
    // console.log(catList);

    // // update when tagList || catList changes...

    // // ***
    // // generate the JSON object for LAB FILES menu section
    // let fileMenuSubList = [];

    // for(let cat of catList){

    //   let catObj = {};
    //   catObj.label = cat;
    //   catObj.value = cat.split(' ').join('-');
    //   catObj.subList = [];

    //   let fileRef = this.storeRef.child(cat);
    //   let fileList = await getFileList(fileRef);
    //   console.log(fileList);

    //   fileList.forEach(file=>{
    //     let obj = {};
    //     obj.label = file.name.split('-').join(' ');
    //     obj.value = file.name;
    //     obj.subList = null;
    //     catObj.subList.push(obj);
    //   })
      
    //   fileMenuSubList.push(catObj);
    // }

    // console.log(fileMenuSubList);
  }

  _onClick = async (e) => {

    // do all these before the await function called
    let fileLink = e.target.dataset.link;
    this.setState({
      activeList: e.target.dataset.value
    })
    this.props.activateMenuLink(e.target.dataset.value);
    // this.props.reportFileNameList(this.state.fileList);

    // get the file content
    let fileContent = await getFileContent(this.storeRef, fileLink);
    // console.log(fileContent);
    this.props.reportFileContent(fileContent);
  }

  render(){
    // console.log(this.state);
    return(
      <aside className="menu">
      {
        this.state.mainMenu.map((item, index)=>{
          return (
            <div key={index} css={css`margin: 10px 0;`}>
              <p className="menu-label">
                {item.label}
              </p>
              <ul className="menu-list">
                {
                  item.subList.map((list, num)=>{
                    return(

                    list.subList
                    ? <li key={num}>
                        <a data-value={item.value}>{list.label}</a>
                        <ul>
                          {
                            list.subList.map((item, i)=>{
                              return(
                              <li key={i}>
                                <a className={
                                item.value === this.state.activeList 
                                ? `is-active`
                                : `not-active`}
                                data-value={item.value}
                                data-link={item.link}
                                onClick={this._onClick}>{item.label}</a>
                              </li>
                              )
                            })
                          }
                        </ul>
                      </li>
                    : <li key={num}>
                        <a className={
                          list.value === this.state.activeList 
                          ? `is-active`
                          : `not-active`}
                          data-value={list.value}
                          data-link={list.link}
                          onClick={this._onClick}>{list.label}</a>
                      </li>
                    )
                   
                  })
                }
              </ul>
            </div>
          )
        })
      }
      </aside>
    )
  }
}

export default withFirebase(MainMenu);

const getFileTagList = async (firebase) => {

  let tagList = [];

  await firebase.filetaglist().get()
    .then((querySnapshot)=>{

      querySnapshot.forEach((doc)=>{
        tagList.push(doc.data().value);
      });

    })
    .catch((error)=>{
      console.log(error);
    });

    return tagList;
}

const getFileCategoryList = async (firebase) => {
  
  let catList = [];

  await firebase.filecategories().get()
    .then((querySnapshot)=>{

      querySnapshot.forEach((doc)=>{
        catList.push(doc.data().value);
      });

    })
    .catch((error)=>{
      console.log(error);
    });

    return catList;
}

const getFileList = async (fileRef) => {
    
  let fileList = [];

  await fileRef.listAll()
  .then((res)=>{

    res.items.forEach((itemRef)=>{
      let obj = {};
      obj.ref = itemRef.fullPath;
      obj.name = itemRef.name.split('_')[0];
      fileList.push(obj);
    });
    
  })
  .catch((err)=>{
    console.log(err);
  });

  return fileList;
}

const getFileContent = async (firebaseStorageRef, fileLink) => {
  
  let fileContent = "log";
  let downloadLink = await getFileDownloadLink(firebaseStorageRef, fileLink);
  // console.log(downloadLink);
  fileContent = await getFileContentFromFirebaseStorage(downloadLink);

  return fileContent;
}

const getFileDownloadLink = async (firebaseStorageRef, fileLink) => {
  let downloadLink = "";
  await firebaseStorageRef.child(fileLink).getDownloadURL()
  .then((url)=>{
    downloadLink = url;
  })
  .catch(err=>{
    console.log(err);
  });
  return downloadLink;
}

const getFileContentFromFirebaseStorage = async (downloadLink) => {
  let fileContent = "";
  let response = await fetch(downloadLink);
  fileContent = response.text();
  return fileContent;
}