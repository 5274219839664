import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import { withFirebase } from './firebase.context';
// import components
import LabMeetingTable from '../elements/Table.LabMeeting';

// convert a firestore doc object into an object
// current being used for conversion
const getSingleScheduleDataAsObject = (doc) => {
  let _item = {};
  // date: "Date",
  // project: "Project-In-Progress",
  // update: "Update-Proposal-JournalClub",
  // room: "Room",
  // time: "Time",
  // firestore docRef
  _item.date = doc.data().date || '';
  _item.project = doc.data().project || '';
  _item.update = doc.data().update || '';
  _item.room = doc.data().room || '';
  _item.time = doc.data().time || '';
  _item.id = doc.id || '';
  return _item;
}

class LabMeetingSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleData: { ...defaultScheduleData }
    }
  }

  componentDidMount() {
    let scheduleData = {};
    let _arr = [];
    let currentDate = new Date(); //whatever today is

    // format data for table component header
    scheduleData.head = [
      "Date",
      "Project-In-Progress",
      "Update-Proposal-JournalClub",
      "Room",
      "Time",
    ];

    this.props.firebase.users()
      .orderBy("date")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // let _item = getSingleScheduleDataAsArray(doc);
          let _item = getSingleScheduleDataAsObject(doc);
          _arr.push(_item);
        });

        // ***
        // filter out the out dated schedules
        // by using currentDate as reference point
        let filteredArr = [];

        // console.log(_arr);
        for (let obj of _arr) {
          let _dateUnadjusted = new Date(obj.date);

          let offset = 240; // Timezone offset for EST in minutes.
          offset += 1380 + 59 // Add 23:59 minutes
          let _date = new Date(_dateUnadjusted.getTime() + offset * 60 * 1000);

          // ***
          // compare each date with currentDate
          // filter out the out dated ones
          if (_date >= currentDate) {
            // console.log(_date);
            filteredArr.push(obj);
          }
        }

        // ***
        // update the table with filtered data
        // generate table component body
        scheduleData.body = filteredArr;
        this.setState({ scheduleData })
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  render() {
    return (
      <div css={css`
        width: 100%;
        padding: 20px 0;
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 20px 0;
        }
      `}>
        <h2>Lab Meeting Schedule</h2>
        <LabMeetingTable scheduleData={this.state.scheduleData} />
      </div>
    )
  }
}

export default withFirebase(LabMeetingSchedule);


const defaultScheduleData = {
  head: [
    "Date",
    "Project-In-Progress",
    "Update-Proposal-JournalClub",
    "Room",
    "Time",
  ],
  body: [
    { date: '', project: '', update: '', room: '', time: '' },
  ]
}