import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Editor, 
  EditorState, 
  convertFromRaw
} from 'draft-js';

import 'draft-js/dist/Draft.css'; //default css

// markdown parser methods
import { markdownToDraft } from 'markdown-draft-js';

/**
 * @param {object} props - props from parent component
 * @param {string} props.mdString
 * 
 */
// only to display markdown contents
// doesn't have to deal with editing
class MDDisplay extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editorState: EditorState.createEmpty()
    };
  }

  componentDidMount(){
    // ***
    // markdown string to draft raw
    let rawObject = markdownToDraft(this.props.mdString);
    // console.log(rawObject);
    let editorState = EditorState.createWithContent(
      convertFromRaw(rawObject)
    );
    this.setState({editorState});
  }

  componentDidUpdate(prevProps){
    if(prevProps.mdString !== this.props.mdString){
        // ***
        // markdown string to draft raw
        let rawObject = markdownToDraft(this.props.mdString);
        // console.log(rawObject);
        let editorState = EditorState.createWithContent(
          convertFromRaw(rawObject)
        );
        this.setState({editorState});
    }
  }


  render(){
    const {editorState} = this.state;
    let className = 'RichEditor-editor';
    return (
      <div css={css`
        width: 100%;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
      `}>

        <div className="RichEditor-root"
          css={css`
          border: 1px solid transparent;
          border-radius: 0px;
          box-shadow: 0 1px 4px rgba(12, 12, 13, 0.0);
          `}
        >
          <div className={className}
            css={css`
              border-top: 1px solid transparent;
            `}
          >
            <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              placeholder="Start writing ..."
              ref="editor"
              onChange={()=>{
                //do nothing
              }}
            />
          </div>
        </div>

      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

export default MDDisplay;

MDDisplay.defaultProps = {
  mdString: "",
}