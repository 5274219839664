import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from './firebase.context';
// import component
import MSStatusTable from '../elements/Table.MSStatus';

const getSingleStatusDataAsObject = (doc) => {
  let _item = {};
  // equipment: "Equipment",
  // status: "Status",
  // firestore docRef
  _item.equipment = doc.data().equipment || '';
  _item.status = doc.data().status || '';
  _item.id = doc.id || '';
  return _item;
}

class MSStatusUpdate extends React.Component {
  constructor(props){
    super(props);
    this.state={
      msStatusData: {...defaultMSStatusData}
    }
  }

  componentDidMount(){

    let msStatusData = {};
    let _arr = [];

    // format data for table component header
    msStatusData.head = [
      "Equipment", "Status"
    ];

    this.props.firebase.msstatus()
      .orderBy("equipment").limit(20)
      .get()
      .then((querySnapshot)=>{
        querySnapshot.forEach((doc)=>{
          let _item = getSingleStatusDataAsObject(doc);
          _arr.push(_item);
        });
        // generate table component body
        msStatusData.body = _arr;
        this.setState({msStatusData})
      })
      .catch((error)=>{
        // console.log(error);
      })
  }


  render(){
    return (
      <div css={css`
        width: 100%;
        padding: 20px 0;
        h2 {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 20px 0;
        }
      `}>
        <h2>MS Equipment Status</h2>
        <MSStatusTable msStatusData={this.state.msStatusData}/>
      </div>
    )
  }
}

export default withFirebase(MSStatusUpdate);

const defaultMSStatusData = {
  head: ["Equipment", "Status"],
  body: [
    {equipment: 'Oribtrap Elite', status: 'Running'},
    {equipment: 'QE', status: 'Running'},
    {equipment: 'QE-HFX', status: 'Running'},
    {equipment: 'OQtrap5500', status: 'Down'},
  ]
}