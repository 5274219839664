import React from 'react';

const dividingArraySegments = (array) => {
  let obj = {};
  let end = array.length;
  obj.col_1 = array.slice(0,3); //2
  obj.col_2 = array.slice(3,4); //1
  obj.col_3 = array.slice(4,9); //5
  obj.col_4 = array.slice(9,end); //last ones
  return obj;
}
const MSBookingTable = (props) => {
  
  return (
    <table className="table is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          {
            props.bookingData.head.map((item, index)=>{
              return (
                <th key={index}>{item}</th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          props.bookingData.body.map((item, index)=>{
            let data = dividingArraySegments(item.data);
            {/* console.log(data); */}
            return(
              <tr key={index}>
                <td>
                  <ul>
                  {
                    data.col_1.map((cell, i)=>{
                      {/* console.log(cell); */}
                      return(
                        <li key={cell.type}>{`${cell.label}: ${cell.value}`}</li>
                      )
                    })
                  }
                  </ul>
                </td>
                <td>
                  <ul>
                  {
                    data.col_2.map((cell, i)=>{
                      {/* console.log(cell); */}
                      return(
                        <li key={cell.type}>{`${cell.value}`}</li>
                      )
                    })
                  }
                  </ul>
                </td>
                <td>
                  <ul>
                  {
                    data.col_3.map((cell, i)=>{
                      {/* console.log(cell); */}
                      return(
                        <li key={cell.type}>{`${cell.label}: ${cell.value}`}</li>
                      )
                    })
                  }
                  </ul>
                </td>
                <td>
                  <ul>
                  {
                    data.col_4.map((cell, i)=>{
                      {/* console.log(cell); */}
                      return(
                        <li key={cell.type}>{`${cell.label}: ${cell.value}`}</li>
                      )
                    })
                  }
                  </ul>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default MSBookingTable;