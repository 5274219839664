import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const MSStatusTable = (props) => {
  return (
    <table className="table is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          {
            props.msStatusData.head.map((item, index)=>{
              return(
                <th key={index}>{item}</th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          props.msStatusData.body.map((item, index)=>{
            return (
              <tr key={index}>
                <td key='equipment'>{item.equipment}</td>
                <td key='staus'>
                  {
                    item.status === 'Running'
                    &&
                    <span className="tag is-success">{item.status}</span>
                  }
                  {
                    item.status === 'Down'
                    &&
                    <span className="tag is-warning">Down</span>
                  }
                  </td>
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

export default MSStatusTable;