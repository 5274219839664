import React from "react";
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// import firebase
import {withFirebase} from "./firebase.context";
import { IconContext } from "react-icons";
import { MdPerson, MdFace, MdAccountCircle } from 'react-icons/md';

class AccountSettings extends React.Component {

  constructor(props){
    super(props);
    this.state={
      passwordReset: '' 
    }
  }

  _resetPassword = () => {
    let email = this.props.firebase.getCurrentuserEmail();
    this.props.firebase.doPasswordReset(email)
    .then(()=>{
      this.setState({passwordReset: 'success'});
    })
    .catch((err)=>{
      this.setState({passwordReset: 'failed'});
    });
  }

  render(){
    return (
      <div css={css`
        width: 100%;
        margin: 30px 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;

      `}>
        <div css={css`
          margin: 30px 0;
          align-self: center;
        `}>
          <IconContext.Provider value={{color:'#3273dc', size: 62}}>
            <MdAccountCircle />
          </IconContext.Provider>
        </div>
        <div css={css`
          margin: 30px 0;
          align-self: center;
        `}>
          Email: {`${this.props.firebase.getCurrentuserEmail()}` || ``}
        </div>
        <div css={css`
          margin: 30px 0;
          align-self: center;
        `}>
            <button className="button is-warning"
              onClick={this._resetPassword}
            >Request Password Reset</button>
          {
            this.state.passwordReset === 'success'
            &&
            <div css={css`
              margin: 20px 0;
            `}>
              <div className="notification is-success">
                A secured link will be sent to your email to allow you to reset your password.
              </div>
            </div>
          }
          {
            this.state.passwordReset === 'failed'
            &&
            <div css={css`
              margin: 20px 0;
            `}>
              <div className="notification">
                Password reset failed.  Please check your internet connection or contact <code>techteam.metalab@gamil.com</code> for support. 
              </div>
            </div>
          }
          
        </div>
      </div>
    )
  }
}

export default withFirebase(AccountSettings);